<template>
  <div>
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'Index',
  computed: {
    name() {
      if (this.$route.name === 'Center') {
        return this.$lang.t('personal.center')
      } else if (this.$route.name === 'Admin') {
        return this.$lang.t('management.name')
      } else {
        return this.$route.meta.title
      }
    }
  }
}
</script>

<style scoped>

</style>
